<template>
  <div class="authorized-callback-error-view">
    <h4>Ошибка авторизации!</h4>

    <div class="py-4">
      <b-icon
          icon="exclamation-circle-fill"
          variant="danger"
          font-scale="3"
      />
    </div>

    <p
        class="mb-4"
    >
      Попробуйте снова
    </p>

    <b-alert
        style="max-width: 320px; margin-left: auto; margin-right: auto"
        :show="error !== undefined"
        variant="danger"
    >
      {{ error }}
    </b-alert>

    <EsiaLoginButton/>

    <p
        class="mt-4 font-weight-bold"
    >
      Вы будете перенаправлены на Бастион ЕСИА
    </p>
  </div>
</template>

<script>
import EsiaLoginButton from '../components/EsiaLoginButton.vue';

export default {
  name: 'AuthorizedCallbackError',

  components: {
    EsiaLoginButton,
  },

  computed: {
    error() {
      return this.$route.params?.error;
    },
  },
};
</script>

<style lang="scss" scoped>
.authorized-callback-error-view {
  padding: 50px 10px;
  text-align: center;
}
</style>
